import { useIntl } from 'react-intl'

import { Button } from 'src/components/atoms/button/Button'
import { Layout } from 'src/components/layout'

export default function Custom404() {
  const { formatMessage } = useIntl()

  return (
    <Layout>
      <div className="px-5 text-center space-y-5">
        <img className="m-auto" src="/cross.png" alt="not_found" />
        <h1>{formatMessage({ id: '404.title' })}</h1>
        <Button href="/" className="max-w-max m-auto uppercase">
          {formatMessage({ id: '404.cta' })}
        </Button>
      </div>
    </Layout>
  )
}
